import { Container, createStyles, Typography } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      Yodo Club &nbsp;
      {new Date().getFullYear()}
    </React.Fragment>
  )
}

interface Props {}

export default function AppFooter({}: Props) {
  const classes = useStyles()

  return (
    <Typography component="footer" className={classes.footer}>
      <Container className={classes.mainContainer}>
        <Copyright />
      </Container>
    </Typography>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      display: 'flex'
    },
    footer: {
      marginTop: theme.spacing(2)
    },
    [`@media print`]: {
      footer: {
        display: 'none'
      }
    }
  })
)
